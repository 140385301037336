#section1 {
    padding-inline: 1rem;
    > .v-card {
        position: relative;
        height: 18rem;
        overflow: hidden;
        .title-wrapper {
            height: 100%;
        }
        .v-card-title {
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: 100%;
        }
        .v-text-overflow {
            font-size: 2rem;
            max-width: 27rem;
        }
        .v-avatar {
            position: absolute;
            background: var(--v-button-background-color, var(--theme--primary));
            top: 1.5rem;
            right: 1.5rem;
            width: 20rem;
            height: 13rem;
            img {
                object-fit: initial;
            }
        }
    }
    .section-wrapper {
        display: flex;
        gap: 1rem;
        .section-left {
            width: 30%;
            // a {
            //     color: #6443fa;
            //     text-decoration: underline;
            // }
        }
        .section-right {
            width: 70%;
        }
    }
    .card-wrapper {
        display: flex;
        gap: 2rem;
        margin: 2rem 1rem;
        .v-card {
            width: 50%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
        }
    }
    .group_events {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block: 2rem 1rem;
        .btn-wrap {
            display: flex;
            gap: 1rem;
        }
    }
    .v-card-actions {
        align-items: flex-end;
        flex-grow: 1;
    }
    .country-info {
        display: flex;
        margin-top: 1rem;
    }
    .group-item {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .federation-table {
        .table-row {
            display: grid;
            grid-template-columns: 4fr 1.5fr 1fr;
        }
    }
    .bold {
        font-weight: 700;
    }
    .table-wrapper {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
    .text-icon-wrap {
        display: flex;
        align-items: center;
    }
    tbody {
        .cell {
            &:first-child {
                display: block;
            }
        }
    }
    .published {
        background: #70d170;
    }
    .icon-wrapper {
        width: 2.5rem;
        background: var(--v-card-background-color, var(--theme--form--field--input--background-subdued));
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    .v-notice {
        .v-button {
            justify-content: flex-end;
            width: max-content;
            flex-grow: 1;
        }
    }
}